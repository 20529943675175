import { useEffect, useState } from 'react';
import rings from '../../assets/rings.webp';
import axios from 'axios';
import settings from '../../config';
import { Link } from 'react-router-dom';

const Main = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}products/`);

      if (response.data.statusCode === 200) {
        setProducts(response.data.data);
      }
    })();
  }, []);

  return (
    <div className='flex flex-col gap-4'>
      <div className='w-full text-center justify-center flex text-2xl '>
        <img src={rings} alt='' />
      </div>

      <div className='flex gap-4'>
        {products.map((product) => (
          <div className='w-50 h-50' key={product.id}>
            <Link to={`product/${product.id}`}>
              <div className='flex gap-2 flex-col'>
                <img
                  src={`${settings.api}uploads/${product.photo}`}
                  alt=''
                  className='object-contain h-36 rounded-xl'
                />
                <div className='text-center'>{product.name}</div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Main;
