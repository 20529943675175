import axios from 'axios';
import { useContext, useEffect, useState } from 'react';

import Button from './Button';
import settings from '../../config';
import editIcon from '../../assets/edit.svg';
import deleteIcon from '../../assets/delete.svg';
import { Link, useParams } from 'react-router-dom';
import { ModalContext } from '../../context';
import InvoicePrint from './InvoicePrint';

const InvoiceDetails = () => {
  const [invoiceDetails, setInvoiceDetails] = useState([]);
  const { invoice_id } = useParams();
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}invoices/details`, {
        id: invoice_id
      });

      if (response.data.statusCode === 200) {
        setInvoiceDetails(response.data.data);
      }
    })();
  }, [invoice_id]);

  const handleOnDelete = async (id) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(
          `${settings.api}invoices/item-delete`,
          {
            id
          }
        );

        if (response.data.statusCode === 200 && response.data.data) {
          setInvoiceDetails(
            invoiceDetails.filter((invoice) => invoice.id !== id)
          );
        }
      }
    });
  };

  return (
    <div className='flex flex-col gap-4'>
      <div className='print:hidden'>
        <Button to={`/admin/invoice-item/create/${invoice_id}`}>
          + Add Invoice Item
        </Button>
        {(!invoiceDetails.length && <div>No Invoice Items Found</div>) || null}

        {(invoiceDetails.length && (
          <table className='w-full'>
            <thead>
              <tr className='bg-gray-100 text-left'>
                <th className='p-2'>Service</th>
                <th className='p-2'>Description</th>
                <th className='p-2'>price</th>
                <th className='p-2'>Edit</th>
                <th className='p-2'>Delete</th>
              </tr>
            </thead>
            <tbody>
              {invoiceDetails.map((invoice) => (
                <tr key={invoice.id}>
                  <td className='p-2'>{invoice.service}</td>
                  <td className='p-2'>{invoice.service_description}</td>
                  <td className='p-2'>{invoice.price}</td>
                  <td className='p-2'>
                    <Link
                      to={`/admin/invoice-item/update/${invoice_id}/${invoice.id}`}
                    >
                      <img
                        src={editIcon}
                        alt='edit'
                        width={20}
                        className='cursor-pointer'
                      />
                    </Link>
                  </td>
                  <td className='p-2'>
                    <img
                      src={deleteIcon}
                      alt='delete'
                      width={20}
                      className='cursor-pointer'
                      onClick={() => handleOnDelete(invoice.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )) ||
          null}
        <hr />
      </div>
      <InvoicePrint invoiceDetails={invoiceDetails} />
    </div>
  );
};

export default InvoiceDetails;
