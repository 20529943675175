import logo from '../../assets/logo-construction.png';
import printerIcon from '../../assets/printer.svg';

const InvoicePrint = ({ invoiceDetails, hidden }) => {
  let subtotal = 0;
  return (
    (invoiceDetails.length && (
      <div className={`mt-4 ${hidden && 'print:block hidden'}`}>
        <div className='flex justify-between'>
          <img src={logo} alt='logo' className='w-[250px] mb-4' />
          <img
            src={printerIcon}
            alt='delete'
            width={20}
            className='cursor-pointer print:hidden'
            onClick={() => window.print()}
          />
        </div>
        <div className='mb-4'>Minarina CO</div>
        <div className='flex gap-4 mb-4'>
          <table className='w-2/3 h-10'>
            <tbody className='border'>
              <tr className='border'>
                <td className='p-2 border w-24'>Address</td>
                <td className='p-2 border'>44 Gurr Cres, Ajax, ON, L1T 2P2</td>
              </tr>
              <tr className='border'>
                <td className='p-2 border'>Phone</td>
                <td className='p-2 border'>289-987-1643</td>
              </tr>
            </tbody>
          </table>
          <table className='w-1/3 h-10'>
            <tbody className='border'>
              <tr className='border'>
                <td className='p-2 border w-24'>Invoice #</td>
                <td className='p-2 border'>{`${new Date(
                  invoiceDetails[0].invoice_date
                ).getFullYear()}000${invoiceDetails[0].invoice_id}`}</td>
              </tr>
              <tr className='border'>
                <td className='p-2 border'>Date</td>
                <td className='p-2 border'>{invoiceDetails[0].invoice_date}</td>
              </tr>
              <tr>
                <td className='p-2 border'>HST #</td>
                <td className='p-2 border'>
                  745302885 <br /> RT0001
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <table className='w-full mb-4'>
          <tbody className='border'>
            <tr className='border'>
              <td className='p-2 border w-20'>To</td>
              <td className='p-2 border'>{invoiceDetails[0].name}</td>
            </tr>
            <tr className='border'>
              <td className='p-2 border'>Address</td>
              <td className='p-2 border'>{invoiceDetails[0].address}</td>
            </tr>
          </tbody>
        </table>
        <table className='w-full border'>
          <thead>
            <tr className='bg-gray-100 text-left'>
              <th className='p-2 border'>Service</th>
              <th className='p-2 border'>Description</th>
              <th className='p-2 border'>price</th>
            </tr>
          </thead>
          <tbody>
            {invoiceDetails.map((invoice) => {
              subtotal += invoice.price;

              return (
                <tr key={invoice.id}>
                  <td className='p-2 border'>{invoice.service}</td>
                  <td className='p-2 border whitespace-pre-wrap'>
                    {invoice.service_description}
                  </td>
                  <td className='p-2 border'>{invoice.price}</td>
                </tr>
              );
            })}
            <tr>
              <td colSpan={2} className='p-2 text-right'>
                Subtotal
              </td>
              <td className='border p-2'>{subtotal.toFixed(2)}</td>
            </tr>
            <tr>
              <td colSpan={2} className='p-2 text-right'>
                HST (ON) @ 13%
              </td>
              <td className='border p-2'>{(subtotal * 0.13).toFixed(2)}</td>
            </tr>
            <tr>
              <td colSpan={2} className='p-2 text-right'>
                Total
              </td>
              <td className='border p-2'>{(subtotal * 1.13).toFixed(2)}</td>
            </tr>
            <tr>
              <td colSpan={2} className='p-2 text-right'>
                Payment
              </td>
              <td className='border p-2'>{(subtotal * 1.13).toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )) ||
    null
  );
};

export default InvoicePrint;
