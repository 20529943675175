const Contact = () => {
  return (
    <div className='flex flex-col gap-4'>
      <div className='w-full text-center text-2xl'>Contact US</div>

      <div className=''>CONTACT INFORMATION - Ontario BOUTIQUE</div>
      <div>
        <iframe
          title='map'
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4040.799818793407!2d-79.06809989124093!3d43.85274224227557!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4df0134f54275%3A0x5a596f307d0131fc!2sActive%20Marketplace%20-%20Durham%20Flea%20Market!5e0!3m2!1sen!2sca!4v1703818394295!5m2!1sen!2sca'
          className='w-full'
          height='450'
          allowfullscreen=''
          loading='lazy'
          referrerpolicy='no-referrer-when-downgrade'
        ></iframe>
      </div>
      <div>19 Notion Rd, Ajax, ON L1S 6K7</div>
      <div>
        Mobile: +1 (289) 987-1643
        <br />
        Email: louransd@yahoo.com
      </div>

      <div>
        HOURS
        <br />
        <br />
        Sat 11:00 AM - 6:00 PM <br />
        Sun 11:00 AM - 5:00 PM PM
      </div>
    </div>
  );
};

export default Contact;
