import axios from 'axios';
import { useEffect, useState } from 'react';

import Button from './Button';
import settings from '../../config';
import { useNavigate, useParams } from 'react-router-dom';

const InvoiceItemForm = () => {
  const navigate = useNavigate();
  const { invoice_id, id } = useParams();
  const [formData, setFormData] = useState({
    service: '',
    service_description: '',
    price: '',
    id,
    invoice_id
  });

  useEffect(() => {
    if (
      invoice_id &&
      id &&
      !formData.service &&
      !formData.service_description &&
      !formData.price
    ) {
      (async () => {
        const response = await axios.post(`${settings.api}invoices/item`, {
          id
        });

        if (response.data.statusCode === 200) {
          setFormData({
            price: response.data.data[0]['price'],
            service: response.data.data[0]['service'],
            service_description: response.data.data[0]['service_description'],
            invoice_id,
            id
          });
        }
      })();
    }
  }, [
    formData.price,
    formData.service,
    formData.service_description,
    id,
    invoice_id
  ]);

  const handleOnClick = async () => {
    if (formData.price && formData.service && formData.service_description) {
      const response = await axios.post(
        `${settings.api}invoices/${id ? 'item-update' : 'item-create'}`,
        formData
      );

      if (response.data.statusCode === 200) {
        navigate(`/admin/invoice/details/${invoice_id}`);
      }
    }
  };

  const handleOnChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  return (
    <div className='flex flex-col gap-4'>
      <div>Invoice Item Form</div>
      <label className='flex items-center gap-4'>
        Service
        <input
          value={formData.service}
          name='service'
          className='border rounded-xl p-2 w-1/2'
          placeholder='Service'
          onChange={handleOnChange}
        />
      </label>
      <label className='flex items-center gap-4'>
        Service Description
        <textarea
          rows={5}
          value={formData.service_description}
          name='service_description'
          className='border rounded-xl p-2 w-1/2'
          placeholder='Service Description'
          onChange={handleOnChange}
        />
      </label>
      <label className='flex items-center gap-4'>
        Price
        <input
          value={formData.price}
          name='price'
          className='border rounded-xl p-2'
          placeholder='Price'
          onChange={handleOnChange}
        />
      </label>
      <Button onClick={handleOnClick}>Submit</Button>
    </div>
  );
};

export default InvoiceItemForm;
