import { Route, Routes } from 'react-router-dom';

import Invoices from './Invoices';
import InvoiceForm from './InvoiceForm';
import InvoiceItemForm from './InvoiceItemForm';
import InvoiceDetails from './InvoiceDetails';
import Products from './Products';
import ProductForm from './ProductForm';
import DashboardContent from './DashboardContent';

const Router = () => {
  return (
    <Routes>
      <Route path='/*' exact element={<DashboardContent />} />
      <Route path='/invoices' exact element={<Invoices />} />
      <Route path='/invoice/create' exact element={<InvoiceForm />} />
      <Route path='/invoice/update/:id' exact element={<InvoiceForm />} />
      <Route
        path='/invoice/details/:invoice_id'
        exact
        element={<InvoiceDetails />}
      />
      <Route
        path='/invoice-item/create/:invoice_id'
        exact
        element={<InvoiceItemForm />}
      />
      <Route
        path='/invoice-item/update/:invoice_id/:id'
        exact
        element={<InvoiceItemForm />}
      />
      <Route path='/products' exact element={<Products />} />
      <Route path='/product/create' exact element={<ProductForm />} />
      <Route path='/product/update/:id' exact element={<ProductForm />} />
    </Routes>
  );
};

export default Router;
