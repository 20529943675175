import axios from 'axios';
import { useEffect, useState } from 'react';

import Button from './Button';
import settings from '../../config';
import { useNavigate, useParams } from 'react-router-dom';

const InvoiceForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    email: '',
    invoice_date: '',
    id: ''
  });

  useEffect(() => {
    if (id && !formData.name && !formData.address && !formData.invoice_date) {
      (async () => {
        const response = await axios.post(
          `${settings.api}invoices/get-invoice`,
          { id }
        );

        if (response.data.statusCode === 200) {
          setFormData({
            name: response.data.data[0]['name'] || '',
            address: response.data.data[0]['address'] || '',
            invoice_date: response.data.data[0]['invoice_date'],
            email: response.data.data[0]['email'] || '',
            id
          });
        }
      })();
    }
  }, [formData.address, formData.invoice_date, formData.name, id]);

  const handleOnClick = async (e) => {
    e.preventDefault();
    if (
      formData.name &&
      formData.address &&
      formData.invoice_date &&
      formData.email
    ) {
      const response = await axios.post(
        `${settings.api}invoices/${id ? 'update' : 'create'}`,
        formData
      );

      if (response.data.statusCode === 200) {
        navigate('/admin/invoices');
      }
    }
  };

  const handleOnChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  return (
    <form className='flex flex-col gap-4' onSubmit={handleOnClick}>
      <div>Invoice Form</div>
      <label className='flex items-center gap-4'>
        Invoice Date
        <input
          value={formData.invoice_date}
          name='invoice_date'
          type='date'
          className='border rounded-xl p-2'
          placeholder='Invoice Date'
          onChange={handleOnChange}
        />
      </label>
      <label className='flex items-center gap-4'>
        Client Name
        <input
          value={formData.name}
          name='name'
          className='border rounded-xl p-2 w-1/2'
          placeholder='Client Name'
          onChange={handleOnChange}
        />
      </label>
      <label className='flex items-center gap-4'>
        Client Email
        <input
          value={formData.email}
          name='email'
          className='border rounded-xl p-2 w-1/2'
          placeholder='Client Email'
          onChange={handleOnChange}
          type='email'
        />
      </label>
      <label className='flex items-center gap-4'>
        Client Address
        <input
          value={formData.address}
          name='address'
          className='border rounded-xl p-2 grow'
          placeholder='Client Address'
          onChange={handleOnChange}
        />
      </label>

      <Button>Submit</Button>
    </form>
  );
};

export default InvoiceForm;
