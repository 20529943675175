import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import settings from '../../config';

const Product = () => {
  const { id } = useParams();
  const [product, setProduct] = useState();

  useEffect(() => {
    if (id && !product) {
      (async () => {
        const response = await axios.post(
          `${settings.api}products/get-product`,
          {
            id
          }
        );

        if (response.data.statusCode === 200) {
          setProduct(response.data.data[0]);
        }
      })();
    }
  }, [id, product]);

  if (!product) {
    return null;
  }

  console.log(product);

  return (
    <div className='flex flex-col w-full sm:flex-row items-start justify-center gap-4 p-4'>
      <div className='w-full sm:w-1/4'>
        <img
          src={`${settings.api}uploads/${product.photo}`}
          alt=''
          className='w-full'
        />
      </div>
      <div className='w-full sm:w-1/2 gap-8 flex flex-col'>
        <div className='text-black text-xl font-bold'>{product.name}</div>
        <div className='text-black'>{product.description}</div>
        <div className=''>
          WEIGHT <span className='text-black'>{product.weight} G</span>
        </div>
        <div className=''>
          PRICE <span className='text-black'>${product.price}</span>
        </div>
      </div>
    </div>
  );
};

export default Product;
