import { useEffect, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { ModalContext } from '../../context';
import useLocalStorage from '../../hooks/useLocalStorage';
import Router from './Router';
import logo from '../../assets/logo-construction.png';

const Item = ({ path, text, action }) => (
  <Link to={path}>
    <button
      onClick={action}
      className='w-full p-4 bg-gray-100 hover:bg-gray-300 text-black items-center flex'
    >
      {text}
    </button>
  </Link>
);

const Dashboard = () => {
  const navigate = useNavigate();
  const [admin, setAdmin] = useLocalStorage('admin');
  const [modal, setModal] = useContext(ModalContext);

  useEffect(() => {
    if (!admin?.adminId || admin?.canEdit === undefined) {
      setAdmin({});
      navigate('/admin/login');
    }
  }, [admin?.adminId, navigate, setAdmin, admin?.canEdit]);

  return (
    <div className='w-full flex md:flex-row flex-col p-4'>
      <div className='w-full md:w-[250px] justify-center md:justify-start flex md:flex-col flex-wrap flex-row font-bold gap-2 pb-4 md:pb-0 print:hidden'>
        <img src={logo} alt='logo' />
        <Item path='/admin' text='Home' />
        <Item path='/admin/invoices' text='Invoices' />
        <Item path='/admin/products' text='Products' />
        <Item
          path='#'
          text='Logout'
          action={() => {
            setAdmin({});
          }}
        />
      </div>
      <div className='w-full px-4 bg-cover'>
        <Router />
      </div>
      {(modal.isModalOpen && (
        <div className='absolute w-full h-full bg-black/80 top-0 left-0 text-rose-500 align-middle flex justify-center items-center font-bold'>
          <div
            className='w-1/4 h-40 bg-slate-100 rounded-xl flex justify-center items-center flex-col gap-5'
            dir='ltr'
          >
            Are you sure you want to delete?
            <div className='flex gap-5' dir='rtl'>
              <button
                onClick={() => {
                  setModal({
                    isModalOpen: false,
                    modalAction: () => {}
                  });
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  modal.modalAction();
                  setModal({
                    isModalOpen: false,
                    modalAction: () => {}
                  });
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )) ||
        null}
    </div>
  );
};

export default Dashboard;
