import { Link } from 'react-router-dom';

const Button = ({ children, to, onClick }) => {
  return (
    <div className='flex'>
      <button
        type='submit'
        className='border rounded-xl p-2 mb-4 bg-green-100 cursor-pointer hover:bg-green-200'
        onClick={(onClick && onClick) || null}
      >
        {(to && <Link to={to}>{children}</Link>) || children}
      </button>
    </div>
  );
};

export default Button;
