import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Button from './Button';
import settings from '../../config';
import detailsIcon from '../../assets/details.png';
import editIcon from '../../assets/edit.svg';
import deleteIcon from '../../assets/delete.svg';
import printerIcon from '../../assets/printer.svg';
import emailIcon from '../../assets/email.svg';
import { ModalContext } from '../../context';
import InvoicePrint from './InvoicePrint';

const Invoices = () => {
  const [invoices, setInvoices] = useState([]);
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [print, setPrint] = useState(false);
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}invoices/`);

      if (response.data.statusCode === 200) {
        setInvoices(response.data.data);
      }
    })();
  }, []);

  const handleOnDelete = async (id) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(`${settings.api}invoices/delete`, {
          id
        });

        if (response.data.statusCode === 200 && response.data.data) {
          setInvoices(invoices.filter((invoice) => invoice.id !== id));
        }
      }
    });
  };

  const handleOnClick = async (id) => {
    const response = await axios.post(`${settings.api}invoices/details`, {
      id
    });

    if (response.data.statusCode === 200) {
      setInvoiceDetails(response.data.data);
      setPrint(true);
    }
  };

  const handleEmail = async (id) => {
    const response = await axios.post(`${settings.api}invoices/email`, {
      invoiceId: id
    });

    if (response.data.statusCode === 200) {
      console.log('email sent');
    }
  };

  useEffect(() => {
    if (invoiceDetails.length && print) {
      window.print();
    }
  }, [invoiceDetails.length, print]);

  useEffect(() => {
    window.onafterprint = function () {
      setPrint(false);
    };
  }, []);

  return (
    <>
      <div className='block print:hidden'>
        <Button to='/admin/invoice/create'>+ Add Invoice</Button>
        {(!invoices.length && <div>No Invoices Found</div>) || null}
        {(invoices.length && (
          <table className='w-full'>
            <thead>
              <tr className='bg-gray-100 text-left'>
                <th className='p-2'>Name</th>
                <th className='p-2'>Address</th>
                <th className='p-2'>Details</th>
                <th className='p-2'>Print</th>
                <th className='p-2'>Email</th>
                <th className='p-2'>Edit</th>
                <th className='p-2'>Delete</th>
              </tr>
            </thead>
            <tbody>
              {invoices.map((invoice) => (
                <tr key={invoice.id}>
                  <td className='p-2'>{invoice.name}</td>
                  <td className='p-2'>{invoice.invoice_date}</td>
                  <td className='p-2'>
                    <Link to={`/admin/invoice/details/${invoice.id}`}>
                      <img
                        src={detailsIcon}
                        alt='details'
                        width={20}
                        className='cursor-pointer'
                      />
                    </Link>
                  </td>
                  <td className='p-2'>
                    <img
                      src={printerIcon}
                      alt='delete'
                      width={20}
                      className='cursor-pointer'
                      onClick={() => handleOnClick(invoice.id)}
                    />
                  </td>
                  <td className='p-2'>
                    {invoice.email && (
                      <img
                        src={emailIcon}
                        alt='email'
                        width={20}
                        className='cursor-pointer'
                        onClick={() => handleEmail(invoice.id)}
                      />
                    )}
                  </td>
                  <td className='p-2'>
                    <Link to={`/admin/invoice/update/${invoice.id}`}>
                      <img
                        src={editIcon}
                        alt='edit'
                        width={20}
                        className='cursor-pointer'
                      />
                    </Link>
                  </td>
                  <td className='p-2'>
                    <img
                      src={deleteIcon}
                      alt='delete'
                      width={20}
                      className='cursor-pointer'
                      onClick={() => handleOnDelete(invoice.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )) ||
          null}
      </div>
      <InvoicePrint invoiceDetails={invoiceDetails} hidden />
    </>
  );
};

export default Invoices;
