import { Link } from 'react-router-dom';

import logo from '../../assets/logo.png';

const MobileNavBar = ({ isOpen, handleOnOpen }) => {
  return (
    <div className='sm:hidden text-lg flex justify-between items-center w-full bg-primary text-textPrimary'>
      <div className='p-4 w-full'>
        <Link to='/' title='Minarina' className='mx-1 flex items-center gap-4'>
          <img src={logo} alt='Minarina' className='h-10' />
          Minarina
        </Link>
      </div>
      <div
        className={`${
          (isOpen && 'hidden') || ''
        } flex flex-col justify-between w-12 h-10 p-3 cursor-pointer`}
        onClick={() => handleOnOpen(!isOpen)}
      >
        <div className='border border-textPrimary'></div>
        <div className='border border-textPrimary'></div>
        <div className='border border-textPrimary'></div>
      </div>
      <div
        className={`${
          (!isOpen && 'hidden') || ''
        } flex flex-col justify-between w-12 h-10 p-3 cursor-pointer text-textPrimary text-2xl`}
        onClick={() => handleOnOpen(!isOpen)}
      >
        &#x2715;
      </div>
    </div>
  );
};

export default MobileNavBar;
