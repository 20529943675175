import { Routes, Route } from 'react-router-dom';

import { ModalProvider } from './context';
import Home from './components/Home';
import Dashboard from './components/Admin/Dashboard';
import AdminLogin from './components/Admin/AdminLogin';

function App() {
  return (
    <ModalProvider>
      <Routes>
        <Route path='/admin/*' element={<Dashboard />} />
        <Route path='/admin/login' exact element={<AdminLogin />} />
        <Route path='/*' exact element={<Home />} />
      </Routes>
    </ModalProvider>
  );
}

export default App;
