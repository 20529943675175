import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Button from './Button';
import settings from '../../config';

export const validateFile = (target) => {
  const allowedExtensions = ['jpg', 'png', 'pdf', 'jpeg'],
    sizeLimit = 5_000_000; // 5 megabyte

  // destructuring file name and size from file object
  const { name: fileName, size: fileSize } = target.files[0];

  /*
   * if filename is apple.png, we split the string to get ["apple","png"]
   * then apply the pop() method to return the file extension
   *
   */
  const fileExtension = fileName.split('.').pop();

  /*
      check if the extension of the uploaded file is included
      in our array of allowed file extensions
    */
  if (!allowedExtensions.includes(fileExtension)) {
    alert('file type not allowed');
    return false;
  } else if (fileSize > sizeLimit) {
    alert('file size too large');
    return false;
  }

  return true;
};

const ProductForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isUploaded, setIsUploaded] = useState(null);
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    description: '',
    weight: '',
    price: '',
    photo: ''
  });

  useEffect(() => {
    if (
      id &&
      !formData.name &&
      !formData.description &&
      !formData.weight &&
      !formData.photo &&
      !formData.price
    ) {
      (async () => {
        const response = await axios.post(
          `${settings.api}products/get-product`,
          { id }
        );

        if (response.data.statusCode === 200) {
          setFormData({
            name: response.data.data[0]['name'] || '',
            description: response.data.data[0]['description'] || '',
            price: response.data.data[0]['price'],
            weight: response.data.data[0]['weight'] || '',
            photo: response.data.data[0]['photo'] || '',
            id
          });
        }
      })();
    }
  }, [
    formData.name,
    id,
    formData.description,
    formData.weight,
    formData.photo,
    formData.price
  ]);

  const handleOnClick = async (e) => {
    e.preventDefault();
    if (
      formData.name &&
      formData.description &&
      formData.weight &&
      formData.photo &&
      formData.price
    ) {
      const response = await axios.post(
        `${settings.api}products/${id ? 'update' : 'create'}`,
        formData
      );

      if (response.data.statusCode === 200) {
        navigate('/admin/products');
      }
    }
  };

  const handleOnChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const handleOnUpload = (e) => {
    if (validateFile(e.target)) {
      const data = new FormData();
      data.append('photo', e.target.files[0]);
      setIsUploaded(1);

      axios
        .post(`${settings.api}products/upload`, data, {
          onUploadProgress: function (progressEvent) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            if (percentCompleted === 100) {
              setIsUploaded(2);
            }
          }
        })
        .then((response) => {
          console.log(response);
          handleOnChange({
            target: {
              name: 'photo',
              value: response.data.data.fileName
            }
          });
        })
        .catch((e) => console.log(e));
    } else {
      return;
    }
  };

  return (
    <form className='flex flex-col gap-4' onSubmit={handleOnClick}>
      <div>Product Form</div>
      <label className='flex items-center gap-4'>
        Name
        <input
          value={formData.name}
          name='name'
          className='border rounded-xl p-2 w-1/2'
          placeholder='Name'
          onChange={handleOnChange}
        />
      </label>
      <label className='flex items-center gap-4'>
        Description
        <input
          value={formData.description}
          name='description'
          className='border rounded-xl p-2 w-1/2'
          placeholder='Description'
          onChange={handleOnChange}
        />
      </label>
      <label className='flex items-center gap-4'>
        Price
        <input
          value={formData.price}
          name='price'
          className='border rounded-xl p-2 w-1/2'
          placeholder='Price'
          onChange={handleOnChange}
        />
      </label>
      <label className='flex items-center gap-4'>
        Weight
        <input
          value={formData.weight}
          name='weight'
          className='border rounded-xl p-2 w-1/2'
          placeholder='Weight'
          onChange={handleOnChange}
        />
      </label>
      <label className='flex items-center gap-4'>
        Photo
        <input
          type='file'
          name='photo'
          className='border rounded-xl p-2 w-1/2'
          onChange={handleOnUpload}
          accept='.jpg, .png, .jpeg'
        />
        {isUploaded === 2 ? 'Photo uploaded!' : ''}
      </label>
      <img
        src={`${settings.api}uploads/${formData.photo}`}
        alt=''
        className='w-32'
      />

      <Button>Submit</Button>
    </form>
  );
};

export default ProductForm;
