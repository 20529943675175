import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Button from './Button';
import settings from '../../config';
import editIcon from '../../assets/edit.svg';
import deleteIcon from '../../assets/delete.svg';
import { ModalContext } from '../../context';

const Products = () => {
  const [products, setProducts] = useState([]);
  const [, setModal] = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const response = await axios.post(`${settings.api}products/`);

      if (response.data.statusCode === 200) {
        setProducts(response.data.data);
      }
    })();
  }, []);

  const handleOnDelete = async (id) => {
    setModal({
      isModalOpen: true,
      modalAction: async () => {
        const response = await axios.post(`${settings.api}products/delete`, {
          id
        });

        if (response.data.statusCode === 200 && response.data.data) {
          setProducts(products.filter((product) => product.id !== id));
        }
      }
    });
  };

  const activateProduct = async (product) => {
    const response = await axios.post(`${settings.api}products/activate`, {
      id: product.id,
      active: +product.active ? 0 : 1
    });

    if (response.data.statusCode === 200 && response.data.data) {
      setProducts(
        products.map((p) =>
          p.id === product.id
            ? {
                ...p,
                active: !+product.active
              }
            : p
        )
      );
    }
  };

  return (
    <div className='block print:hidden'>
      <Button to='/admin/product/create'>+ Add Product</Button>
      {!products.length ? <div>No Products Found</div> : null}
      {products.length ? (
        <table className='w-full'>
          <thead>
            <tr className='bg-gray-100 text-left'>
              <th className='p-2'>Name</th>
              <th className='p-2'>Weight</th>
              <th className='p-2'>Price</th>
              <th className='p-2'>Photo</th>
              <th className='p-2 text-center'>Activate</th>
              <th className='p-2 text-center'>Edit</th>
              <th className='p-2 text-center'>Delete</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <tr key={product.id}>
                <td className='p-2'>{product.name}</td>
                <td className='p-2'>{product.weight}</td>
                <td className='p-2'>{product.price}</td>
                <td className='p-2'>
                  <img
                    src={`${settings.api}uploads/${product.photo}`}
                    alt=''
                    className='w-32'
                  />
                </td>
                <th className='p-2'>
                  <div
                    className='flex flex-col w-full items-center cursor-pointer'
                    onClick={() => activateProduct(product)}
                  >
                    <div
                      className={`flex rounded-full w-12 p-1 ${
                        product.active
                          ? 'bg-textPrimary justify-end'
                          : 'bg-textPrimary/50'
                      }`}
                    >
                      <div className='flex bg-primary rounded-full h-4 w-1/2'></div>
                    </div>
                  </div>
                </th>
                <td className='p-2'>
                  <div className='flex justify-center'>
                    <Link to={`/admin/product/update/${product.id}`}>
                      <img
                        src={editIcon}
                        alt='edit'
                        width={20}
                        className='cursor-pointer'
                      />
                    </Link>
                  </div>
                </td>
                <td className='p-2 text-center'>
                  <div className='flex justify-center'>
                    <img
                      src={deleteIcon}
                      alt='delete'
                      width={20}
                      className='cursor-pointer'
                      onClick={() => handleOnDelete(product.id)}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : null}
    </div>
  );
};

export default Products;
