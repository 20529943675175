import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import settings from '../../config';
import useLocalStorage from '../../hooks/useLocalStorage';

const AdminLogin = () => {
  const navigate = useNavigate();
  const [admin, setAdmin] = useLocalStorage('admin');
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    if (admin?.adminId && admin?.canEdit !== undefined) {
      navigate('/admin');
    }
  }, [admin?.adminId, navigate, admin?.canEdit]);

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();

        const response = await axios.post(
          `${settings.api}admin/login`,
          formData
        );

        if (response.data.statusCode === 200) {
          setAdmin(response.data.data);
        }
      }}
    >
      <div className='w-full md:w-1/2 p-8 m-auto'>
        <div className='shadow-xl hover:shadow-2xl bg-white w-full rounded-xl divide-solid divide-y flex flex-col px-4'>
          <div className='flex flex-col text-sm text-gray-500 py-5'>
            <div className='flex flex-row justify-center space-x-10 w-full mt-5'>
              <div className='w-full font-bold text-xl text-center mb-5'>
                Welcome to Minarina Dashboard
              </div>
            </div>
            <div className='flex space-x-10 w-full'>
              <label className='w-full'>
                Username
                <input
                  placeholder='username'
                  value={formData.username}
                  onChange={onChange}
                  className='w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                  required
                  name='username'
                />
              </label>
            </div>
            <div className='flex space-x-10 w-full'>
              <label className='w-full'>
                Password
                <input
                  placeholder='Password'
                  value={formData.password}
                  name='password'
                  onChange={onChange}
                  className='w-full border rounded-lg my-2 p-2 invalid:border-pink-500 invalid:text-pink-600
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                  required
                  type='password'
                />
              </label>
            </div>
            <div className='flex flex-row justify-center space-x-10 w-full mt-5'>
              <button
                className='bg-slate-500 text-white p-4 rounded'
                type='submit'
                disabled={!(formData.username && formData.password)}
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AdminLogin;
