import { Route, Routes } from 'react-router-dom';

import Contact from '../Contact';
import NavBar from '../NavBar';
import Main from '../Main';
import MobileNavBar from '../MobileNavBar';
import { useState } from 'react';
import Product from '../Product';

const Home = () => {
  const [isNavBarOpen, setIsNavBarOpen] = useState(false);

  const animation = '-translate-x-3/4 sm:translate-x-0';

  return (
    <div className='w-full bg-primary min-h-full justify-between flex flex-col'>
      <div className='flex flex-col pt-7 min-h-full w-full sm:w-[1124px] mx-auto'>
        <div className='flex flex-row justify-between w-full'>
          <div
            className={`z-10 bg-primary w-full ease-out duration-700 z-1 ${
              (isNavBarOpen && animation) || ''
            }`}
          >
            <div
              className={`flex flex-col w-full min-h-full  sm:w-[1124px] mx-auto`}
            >
              <MobileNavBar
                isOpen={isNavBarOpen}
                handleOnOpen={setIsNavBarOpen}
              />
              <div className='grow pt-10 sm:pt-40'>
                <Routes>
                  <Route path='/' element={<Main />} />
                  <Route path='/product/:id' element={<Product />} />
                  <Route path='/contact' element={<Contact />} />
                </Routes>
              </div>
            </div>
          </div>
          <NavBar setIsNavBarOpen={setIsNavBarOpen} />
        </div>
      </div>
      <div className='h-32 bg-textPrimary text-primary text-center items-center flex justify-center mt-8'>
        © 2024, Minarina
      </div>
    </div>
  );
};

export default Home;
