import { Link } from 'react-router-dom';

import logo from '../../assets/logo.png';

const NavBar = ({ setIsNavBarOpen }) => {
  return (
    <div className='flex items-center text-lg text-textPrimary ml-[-100%] top-0 right-0 sm:top-auto sm:right-auto sm:relative w-full h-full sm:h-20 sm:w-full sm:bg-transparent z-0 sm:z-10'>
      <div className='flex flex-row items-center justify-between bg-primary h-full w-full'>
        <div className='flex flex-col sm:p-0 items-center sm:gap-4 sm:flex-row h-full w-1/3  sm:w-1/2'>
          <div className='hidden sm:flex flex-row items-center w-full gap-4'>
            <div>
              <Link to='/' title='Minarina'>
                Minarina
              </Link>
            </div>
          </div>
        </div>

        <div className='hidden sm:block'>
          <Link to='/' title='Minarina'>
            <img src={logo} alt='Minarina' className={`h-28`} />
          </Link>
        </div>

        <div className='flex flex-col items-start sm:justify-end sm:gap-4 sm:flex-row sm:items-center h-full w-3/4 sm:w-1/2'>
          <Link onClick={() => setIsNavBarOpen(false)} to='/' title='Home'>
            <div className={`py-2 hover:cursor-pointer hover:underline`}>
              Home
            </div>
          </Link>
          <Link
            onClick={() => setIsNavBarOpen(false)}
            to='/contact'
            title='Contact'
          >
            <div className={`py-2 hover:cursor-pointer hover:underline`}>
              Contact
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
